<template>
  <div>
    <Nav />
    <HeroCover
      v-bind:title="title"
      v-bind:tagline="tagline"
      v-bind:cover="require('../../assets/img/hero_cover.png')"
    />
    <div class="padd_">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <div class="temp__text pa-3 mb-3">
              <h2 class="">Apa itu konseling?</h2>
              <p>
                Konseling secara umum dapat diartikan sebagai pemberian
                penerangan, informasi, atau saran kepada pihak lain. Di dalam
                proses konseling, terdapat hubungan timbal balik antara dua
                individu, di mana seorang konselor akan membantu seorang konseli
                untuk memahami permasalahan yang sedang dihadapi.
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="temp__text pa-3 mb-3">
              <h2>Apa yang membedakan konseling dengan metode lainnya?</h2>
              <p>
                Sering mendengar mensenai coaching dan counseling? Konseling dan
                coaching berbeda metodenya. Coaching, seorang coach memandu
                coachee untuk menemukan solusinya. Sementara dalam counseling,
                konseli akan didengarkan, didukung, dan dibantu dalam memahami
                permasalahan yang dihadapi.
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="temp__text pa-3 mb-3">
              <h2>Berapa lama?</h2>
              <p>
                Konseling umumnya dapat berlangsung satu hingga satu setengah
                jam. Dapat melalui tatap muka langsung, atau media lainnya,
                seperti panggilan video, panggilan suara ataupun komunikasi
                dalam bentuk teks.
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="temp__text pa-3 mb-3">
              <h2>Bagaimana jika butuh counseling?</h2>
              <p>
                Anda dapat mencari psikolog yang sesuai dengan kebutuhan Anda,
                membuat janji sesuai dengan prosedur yang ada, dan melakukan
                sesi konseling.
              </p>
            </div></v-col
          >
        </v-row>
      </v-container>
    </div>
    <v-divider></v-divider>
    <div class="pa-3">
      <div></div>
      <div>
        <h2 class="mb-3">
          Apa permasalahan yang dapat dibantu melalui konseling ?
        </h2>
        <section class="d-flex mb-1">
          <div class="mr-3">
            <v-icon>mdi-spa</v-icon>
          </div>
          <p class="ma-0">Pengelolaan emosi yang sulit.</p>
        </section>
        <section class="d-flex mb-1">
          <div class="mr-3">
            <v-icon>mdi-spa</v-icon>
          </div>
          <p class="ma-0">
            Kelelahan emosional atau tekanan psikologis akibat situasi yang
            sulit atau menekan, seperti konflik perkawinan, stress kerja,
            kondisi sakit fisik, bencana, kedukaan, dll.
          </p>
        </section>
        <section class="d-flex mb-1">
          <div class="mr-3">
            <v-icon>mdi-spa</v-icon>
          </div>
          <p class="ma-0">
            Masalah yang berkaitan dengan gangguan belajar, seperti kesulitan
            belajar, masalah konsentrasi, dll.
          </p>
        </section>
        <section class="d-flex mb-1">
          <div class="mr-3">
            <v-icon>mdi-spa</v-icon>
          </div>
          <p class="ma-0">
            Masalah yang berkaitan dengan gangguan perilaku, seperti
            agresivitas, kecanduan, dll.
          </p>
        </section>
        <section class="d-flex mb-1">
          <div class="mr-3">
            <v-icon>mdi-spa</v-icon>
          </div>
          <p class="ma-0">
            Masalah yang berkaitan dengan pengembangan diri, seperti kurang
            percaya diri, manajemen waktu, dll.
          </p>
        </section>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeroCover from "../../components/_base/heroCover.vue";
import Nav from "../../components/General/Nav.vue";
import Footer from "../General/Footer.vue";

export default {
  name: "konseling",
  components: { HeroCover, Nav, Footer },
  data() {
    return {
      title: "Tumbuhkan ketenangan dan kedamaian dari dalam diri",
      tagline:
        "Melakukan konsultasi bersama Psikolog terpercaya dari paguyuban UGM"
    };
  }
};
</script>

<style scoped>
.temp__text {
  transition: 0.5s;
  border-radius: 10px;
}
.temp__text:hover {
  transform: scale(1.1);
  border: none;
  background: #9c27b0;
  color: white;
  -webkit-box-shadow: 0px 0px 21px -5px rgba(156, 39, 176, 0.78);
  -moz-box-shadow: 0px 0px 21px -5px rgba(156, 39, 176, 0.78);
  box-shadow: 0px 0px 21px -5px rgba(156, 39, 176, 0.78);
}
</style>
